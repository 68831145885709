import * as mobxReact from 'mobx-react';
import * as React from 'react';
import { VideoPlaylist } from '../../ui/video_playlist/video_playlist';
import styles from './javascript_tutorials.module.css';

@mobxReact.observer
export class JavascriptTutorials extends React.Component {
  private readonly videos = [
    {
      videoId: '6gn3H4miRmQ',
      duration: '20:05',
      videoName: 'The DOM',
    },
    {
      videoId: '51O79YLjYfM',
      duration: '13:12',
      videoName: 'Local Storage',
    },
    {
      videoId: 'mO8AyWYnEPA',
      duration: '17:56',
      videoName: 'Forms in HTML and JS',
    },
    {
      videoId: 'SdEfIJrruL0',
      duration: '29:22',
      videoName: 'JS Events',
    }
  ];

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.textDescription}>
          JavaScript Web Development tutorials covering the basics of the DOM, Events, Forms and Localstorage. 
        </div>
        <VideoPlaylist videos={this.videos}/>
      </div>
    );
  }
}
