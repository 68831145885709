import * as React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from 'react-router-dom';
import { Blog } from './blog/blog';
import { HelpButton } from './help_button/help_button';
import { Home, HomeContent } from './home/home';
import { Navbar } from './navbar/navbar';
import { PhotoJournals } from './photography/photography';
import { Projects } from './projects/projects';
import styles from './main.module.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// "Feature flag" for version of the website with only the blog page.
// const enableBlogOnlyView = true; 

// "Feature flag" for version of the website with only the blog page.
const blogType: 'blog_only' | 'blogs_and_videos' | 'all' = 'blogs_and_videos';

export class Main extends React.Component {
  render() {
    const homeContent = blogType !== 'all'
        ? <div></div>
        : <HomeContent/>;

    return (
      <Router>
        <div className={styles.app}>
          <div role="navigation">
            <Navbar blogType={blogType}/>
          </div>
          <div className={styles.content} role="main">
            {blogType === 'blog_only' && (
              <Home homeContent={<Blog/>}/>
            )}
            {blogType === 'blogs_and_videos' && (
              <Switch>
                <Route exact path="/">
                  <ScrollToTop />
                  <Home homeContent={<Blog/>}/>
                </Route>
                <Route path="/blog">
                  <ScrollToTop />
                  <Home homeContent={<Blog/>}/>
                </Route>
                <Route path="/projects">
                  <ScrollToTop />
                  <Home homeContent={<Projects videoMode={true}/>}/>
                </Route>
              </Switch>
            )}
            {blogType === 'all' && (
              <Switch>
                <Route exact path="/">
                  <ScrollToTop />
                  <Home homeContent={homeContent}/>
                </Route>
                <Route path="/photojournals">
                  <ScrollToTop />
                  <PhotoJournals />
                </Route>
                <Route path="/blog">
                  <ScrollToTop />
                  <Blog />
                </Route>
                <Route path="/projects">
                  <ScrollToTop />
                  <Projects videoMode={false}/>
                </Route>
              </Switch>
            )}
          </div>
          <div className={styles.helpButton} role="complementary">
            <HelpButton />
          </div>
        </div>
      </Router>
    );
  }
}
