import * as mobx from 'mobx';
import * as mobxReact from 'mobx-react';
import * as React from 'react';
import styles from './projects.module.css';
import { Card } from '../card/card';
import { DigitalPetGame } from './digital_pet_game/digital_pet_game';
import { JavascriptTutorials } from './javascript_tutorials/javascript_tutorials';
import { CanvaYoutube } from './canva_youtube/canva_youtube';
import { NewtabProject } from './newtab/newtab';
import { FlagProject } from './flags/flags';
import { YoutubeFocusModeProject } from './youtube_extension/youtube_extension';
import { PersonalWebsite } from './personal_website/personal_website';
import { PythonTutorials } from './python_tutorials/python_tutorials';

import canvaYoutubeThumbnail from './canva_youtube_thumbnail.jpg';
import newtabExtensionThumbnail from './newtab_extension_thumbnail.jpg';
import youtubeExtensionThumbnail from './youtube_extension_thumbnail.jpg';
import pythonTutorialsThumbnail from './python_tutorials_thumbnail.jpg';
import javascriptTutorialsThumbnail from './javascript_tutorials_thumbnail.png';
import personalWebsiteThumbnail from './personal_website_thumbnail.jpg';
import digitalPetGameThumbnail from './digital_pet_game_thumbnail.png';
import worldFlagsThumbnail from './world_flags_thumbnail.png';

type Project = {
  name: string;
  displayName: string;
  image: string;
  videos?: boolean,
};

const projects: Project[] = [
  {
    name: 'flags-quiz-game',
    displayName: 'World Flags Quiz Website',
    image: worldFlagsThumbnail,
  },
  {
    name: 'youtube-focus-mode',
    displayName: 'YouTube Focus Mode Chrome Extension',
    image: youtubeExtensionThumbnail,
  },
  {
    name: 'digital-pet-game',
    displayName: 'Digital Pet Game',
    image: digitalPetGameThumbnail,
  },
  {
    name: 'simple-newtab',
    displayName: 'Simple Newtab Chrome Extension',
    image: newtabExtensionThumbnail,
  },
  {
    name: 'canva-youtube',
    displayName: 'Canva YouTube Videos',
    image: canvaYoutubeThumbnail,
    videos: true,
  },
  {
    name: 'python-tutorials',
    displayName: 'Python Tutorials Videos',
    image: pythonTutorialsThumbnail,
    videos: true,
  },
  {
    name: 'javascript-tutorials',
    displayName: 'JavaScript Tutorials Videos',
    image: javascriptTutorialsThumbnail,
    videos: true,
  },
  {
    name: 'personal-website',
    displayName: 'Personal Website',
    image: personalWebsiteThumbnail,
  },
];

@mobxReact.observer
export class Projects extends React.Component<{ videoMode: boolean }> {
  @mobx.observable.ref
  private currentProject?: string;

  componentDidMount() {
    const hash = window.location.hash;
    this.currentProject = hash.substring(1);
    window.addEventListener('hashchange', this.onHashChange);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.onHashChange);
  }

  private readonly onHashChange = () => {
    const hash = window.location.hash;
    this.currentProject = hash.substring(1);
  };

  @mobx.action.bound
  private readonly onCardClick = (name: string) => {
    window.location.hash = name;
    this.currentProject = name;
  };

  private getDisplayName = (projectName: string) => {
    return projects
        .filter((v) => this.props.videoMode ? !!v.videos : true)
        .find((p) => p.name === projectName)?.displayName!;
  };

  private readonly renderCurrentProject = () => {
    switch (this.currentProject) {
      case 'flags-quiz-game':
        return <FlagProject />;
      case 'simple-newtab':
        return <NewtabProject />;
      case 'digital-pet-game':
        return <DigitalPetGame />;
      case 'youtube-focus-mode':
        return <YoutubeFocusModeProject />;
      case 'personal-website':
        return <PersonalWebsite />;
      case 'python-tutorials':
        return <PythonTutorials />;
      case 'javascript-tutorials':
        return <JavascriptTutorials />;
      case 'canva-youtube':
        return <CanvaYoutube />;
      default:
        return <div>Not found</div>;
    }
  };

  render() {
    return this.currentProject ? (
      <div className={styles.projectPage}>
        <div className={styles.projectTitle}>
          {this.getDisplayName(this.currentProject).toUpperCase()}
        </div>
        {this.renderCurrentProject()}
      </div>
    ) : (
      <ProjectsGrid
        onCardClick={this.onCardClick}
        wrap={true}
        largeImage={true}
        videoMode={this.props.videoMode}
      />
    );
  }
}

export const ProjectsGrid = ({
  onCardClick,
  wrap,
  numProjects,
  largeImage,
  videoMode,
}: {
  onCardClick(name: string): void;
  wrap?: boolean;
  numProjects?: number;
  largeImage: boolean;
  videoMode: boolean;
}) => (
  <div
    className={styles.projectCards}
    style={{ flexWrap: wrap ? 'wrap' : 'nowrap' }}
  >
    {projects.filter((v) => videoMode ? !!v.videos : true)
      .slice(0, numProjects)
      .map(({ displayName, image, name }: Project, i: number) => (
        <div className={styles.projectCard} key={i}>
          <Card
            text={displayName}
            image={image}
            onClick={() => onCardClick(name)}
            largeImage={largeImage}
            ariaLabel={`Open project details: ${displayName}`}
          />
        </div>
      ))}
  </div>
);
