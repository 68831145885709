import * as mobxReact from 'mobx-react';
import * as React from 'react';
import { VideoPlaylist } from '../../ui/video_playlist/video_playlist';
import styles from '../javascript_tutorials/javascript_tutorials.module.css';

@mobxReact.observer
export class CanvaYoutube extends React.Component {
  private readonly videos = [
    {
      videoId: 'p8HOOEfGrE0',
      duration: '9:10',
      videoName: 'Free online storyboard creator',
    },
    {
      videoId: 'X--DudASwOE',
      duration: '1:43:33',
      startTime: '2769',
      videoName: 'Canva Create - The Future is Visual (Docs Launch section)',
    },
    {
      videoId: 'YMQEjf0_C-M',
      duration: '1:30:51',
      startTime: '2228',
      videoName: 'Canva Create - Brand New Era (Docs Magic Write section)',
    },
  ];

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.textDescription}>
          Videos on Canva's official YouTube page including tutorials, and Canva Create presentations.
        </div>
        <VideoPlaylist videos={this.videos}/>
      </div>
    );
  }
}
