import * as React from 'react';
import styles from '../projects.module.css';
import flagsGif from './flags_gif.gif';
import { LaptopDemo } from '../ui/laptop_demo/laptop_demo';
import { Button } from '../../ui/button';
import { Languages } from '../ui/languages/languages';
import classNames from 'classnames';

export class FlagProject extends React.Component {
  render() {
    return (
      <>
        <LaptopDemo demoImg={flagsGif} />
        <div className={styles.content}>
          <div className={styles.descriptionText}>
            A quiz guessing game for the flags of the world!
          </div>
          <div className={styles.languagesSection}>
            <Languages languages={['React', 'HTML', 'CSS', 'MobX']} />
          </div>
          <div className={styles.actionButton}>
            <Button
              label="View game"
              link="https://flags-quiz-a2035.web.app/"
              external={true}
            />
          </div>
          <div className={styles.actionButton}>
            <Button
              label="View source >"
              link="https://github.com/annaazzam/flags-quiz"
              external={true}
            />
          </div>
          <br />
          <br />
          <br />
        </div>
      </>
    );
  }
}
