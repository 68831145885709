export type ImageCollection = {
  photos: any[];
  thumbnails: any[];
  displayName: string;
  folderName: string;
  date: string;
};

const seattlePhotos = [
  'https://i.ibb.co/2KbKhkk/2.jpg',
  'https://i.ibb.co/rMfwtGX/3.jpg',
  'https://i.ibb.co/yNFDvyr/4.jpg',
  'https://i.ibb.co/mXvvkvB/5.jpg',
  'https://i.ibb.co/6Bq0hMK/6.jpg',
  'https://i.ibb.co/bQdwvD8/7.jpg',
  'https://i.ibb.co/Nyw09Hn/8.jpg',
  'https://i.ibb.co/R71HV3W/9.jpg',
  'https://i.ibb.co/PM9s6mR/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/rs8KgFW/11.jpg',
  'https://i.ibb.co/7JL3yzy/12.jpg',
  'https://i.ibb.co/2KM1Q2S/13.jpg',
  'https://i.ibb.co/ZGbdXkD/14.jpg',
  'https://i.ibb.co/jvxWN9t/15.jpg',
  'https://i.ibb.co/CtxJLB0/16.jpg',
  'https://i.ibb.co/bBrWg68/17.jpg',
  'https://i.ibb.co/r3m55FG/18.jpg',
  'https://i.ibb.co/xHzWSGN/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/qNmcmcf/20.jpg',
  'https://i.ibb.co/SyGNKp1/21.jpg',
  'https://i.ibb.co/YpZjvbS/22.jpg',
  'https://i.ibb.co/Lkt0GqB/23.jpg',
  'https://i.ibb.co/nBwrTfq/24.jpg',
  'https://i.ibb.co/71HCJV9/25.jpg',
  'https://i.ibb.co/vk9vnwH/26.jpg',
  'https://i.ibb.co/m5Knb8B/27.jpg',
  'https://i.ibb.co/KbVM32M/28.jpg',
  'https://i.ibb.co/JxMR1M8/29.jpg',
  'https://i.ibb.co/6bg0jG5/30.jpg',
  'https://i.ibb.co/wQFScNc/31.jpg',
  'https://i.ibb.co/6gxxN2H/32.jpg',
  'https://i.ibb.co/Gpjc5nf/33.jpg',
  'https://i.ibb.co/Fw4nCvR/34.jpg',
  'https://i.ibb.co/HNR4TMZ/35.jpg',
  'https://i.ibb.co/Y3FC8Zq/36.jpg',
  'https://i.ibb.co/DCgjdmY/37.jpg',
  'https://i.ibb.co/K6hqcpt/38.jpg',
  'https://i.ibb.co/xH3RgRT/39.jpg',
  'https://i.ibb.co/4Mh3Rrg/40.jpg',
  'https://i.ibb.co/zbDFLkX/41.jpg',
  'https://i.ibb.co/r7TJDv6/42.jpg',
  'https://i.ibb.co/w6sbjpW/43.jpg',
  'https://i.ibb.co/3sbTvxr/44.jpg',
  'https://i.ibb.co/tmjmLVV/45.jpg',
  'https://i.ibb.co/fCvTHX5/46.jpg',
];

const seattleThumbnails = [
  'https://i.ibb.co/RH5HbDD/2.jpg',
  'https://i.ibb.co/7VN1zJ0/3.jpg',
  'https://i.ibb.co/2F3wVtT/4.jpg',
  'https://i.ibb.co/1dss1sn/5.jpg',
  'https://i.ibb.co/L5sdwFT/6.jpg',
  'https://i.ibb.co/Fm7ZYCj/7.jpg',
  'https://i.ibb.co/vj2N3Kv/8.jpg',
  'https://i.ibb.co/V3dBPg8/9.jpg',
  'https://i.ibb.co/ypYmQfD/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/m8Zrmbx/11.jpg',
  'https://i.ibb.co/wNjn6L6/12.jpg',
  'https://i.ibb.co/cT8SH5w/13.jpg',
  'https://i.ibb.co/8NQMsSZ/14.jpg',
  'https://i.ibb.co/xScfkKZ/15.jpg',
  'https://i.ibb.co/kyY3ZKc/16.jpg',
  'https://i.ibb.co/MVR2Dg3/17.jpg',
  'https://i.ibb.co/1MLRR7f/18.jpg',
  'https://i.ibb.co/9ty6927/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/HtGbGbs/20.jpg',
  'https://i.ibb.co/ZX7dhCw/21.jpg',
  'https://i.ibb.co/5BFcq23/22.jpg',
  'https://i.ibb.co/5M817zC/23.jpg',
  'https://i.ibb.co/DY14NkP/24.jpg',
  'https://i.ibb.co/gRG9Mwn/25.jpg',
  'https://i.ibb.co/NKRnBVs/26.jpg',
  'https://i.ibb.co/hFNGLfM/27.jpg',
  'https://i.ibb.co/NtW8H98/28.jpg',
  'https://i.ibb.co/1swfgwB/29.jpg',
  'https://i.ibb.co/y5WFHKJ/30.jpg',
  'https://i.ibb.co/FYL5HbH/31.jpg',
  'https://i.ibb.co/zGYYN1X/32.jpg',
  'https://i.ibb.co/fF74xqV/33.jpg',
  'https://i.ibb.co/G2QFNwz/34.jpg',
  'https://i.ibb.co/wJ8ghbt/35.jpg',
  'https://i.ibb.co/7XdfV46/36.jpg',
  'https://i.ibb.co/gTFcYQd/37.jpg',
  'https://i.ibb.co/cJ3FKSW/38.jpg',
  'https://i.ibb.co/k1MWDWC/39.jpg',
  'https://i.ibb.co/Npcb2Bn/40.jpg',
  'https://i.ibb.co/6bL8pdH/41.jpg',
  'https://i.ibb.co/bWqnc7B/42.jpg',
  'https://i.ibb.co/MN6jQPV/43.jpg',
  'https://i.ibb.co/th6Hz0m/44.jpg',
  'https://i.ibb.co/GxYxHzz/45.jpg',
  'https://i.ibb.co/hKfTVgj/46.jpg',
];

const weddingPartyImages = [
  'https://i.ibb.co/TMdrH9f/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/dGyyvJc/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/ZHLW7TJ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/wRKqbxn/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/F81Rm9t/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Q6DZFCr/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/MghWHnK/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/pLXSd27/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/WD4rPGV/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/jHH17Pz/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/f0Wktsz/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/vqXTSNR/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/NjR0Nmv/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/DLWQw4p/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Y2QhvjJ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/n6Z9BgJ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/KGSZCp2/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/GVt3XcF/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/TMdg7wZ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/XXf06h9/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/fnRZ5Yz/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/ynhkgCn/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/v4kLWWj/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/j4pSMFc/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/v3cwHF2/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/dDF0f3w/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/RzNFBPx/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/tMmb9gS/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/HP8R7B6/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Jj5mP05/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/56fTMMj/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/HDb96HH/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/H40WLSL/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/dGppxzw/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/cx60D4q/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/tZgpVzR/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/VMd7mTJ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Msvq47N/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/pPM5GDy/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/JmdR1Zy/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/cgWMSnk/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/q9PbgRN/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/N1MChFr/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/PD239rj/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/mzGdZ3f/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/rFZ02PY/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/4YPk1bS/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/fvzXsDX/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/nbbdM7s/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/4S2FqpB/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/rsR52fj/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/jTT75h0/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/0qV5mYV/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/zhzp7S0/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/b6tdrCx/Processed-with-VSCO-with-hb1-preset.jpg',
];

const weddingPartyThumbnails = [
  'https://i.ibb.co/Wvjscmh/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/86VVw4B/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/q0ykV1n/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/px3Ts9H/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/QYVypZG/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/wKcTNwM/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/7Sz8DvB/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/jr4SDhN/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/tZT0xQL/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/vDDK26h/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/ryhxMVR/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/ZX2F0Q7/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/3NKxFM3/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Fn3z86V/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/PxjgBCJ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/jbBmz6X/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/FWCvGL5/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/fCnGb4Q/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/HT3HbK0/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/dBsCXdS/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/4YyCGZn/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/DDGMwFD/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/yFqgCCQ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/YjrxkGz/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Byfztp8/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/w4VNdbF/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/m60jXtL/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/kgHhtjp/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Lg7BSYm/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/5hBGVtB/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/Ms3n221/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/DDvjhRR/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/xLypKkK/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/18XXjwt/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/x31WJxZ/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/n39MxBp/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/8PJWc9N/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/ZVjyPxT/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/vBTRg5Y/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/ZGJ6j9Y/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/5MQtgHG/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/92CR4qb/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/9w7gXph/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/RjXZBgb/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/nkb4dFx/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/LYCRdwm/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/7R2hk3p/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/0tRnxsn/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/t44jpqM/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/QHkJgMs/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/4dn4Rgq/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/W66S3n8/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/hs10L21/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/fYsfnCP/Processed-with-VSCO-with-hb1-preset.jpg',
  'https://i.ibb.co/0n5Jf6k/Processed-with-VSCO-with-hb1-preset.jpg',
];

const atlantaPhotos = [
  'https://i.ibb.co/3pL7SXv/1.jpg',
  'https://i.ibb.co/pwNJr65/2.jpg',
  'https://i.ibb.co/zfn9kb6/4.jpg',
  'https://i.ibb.co/GMg46X3/3.jpg',
  'https://i.ibb.co/Bq0BBy4/5.jpg',
  'https://i.ibb.co/GWdyrWZ/6.jpg',
  'https://i.ibb.co/JqRJCyq/7.jpg',
  'https://i.ibb.co/SfKqrWr/8.jpg',
  'https://i.ibb.co/JmBGMyt/9.jpg',
  'https://i.ibb.co/RpvqSPk/10.jpg',
  'https://i.ibb.co/6y1p1JX/11.jpg',
  'https://i.ibb.co/gV77fy5/12.jpg',
  'https://i.ibb.co/hBdrWwq/13.jpg',
  'https://i.ibb.co/Njf7VFt/14.jpg',
  'https://i.ibb.co/6Y5VRnH/15.jpg',
  'https://i.ibb.co/t4HkRJH/16.jpg',
  'https://i.ibb.co/W6YtmWd/17.jpg',
  'https://i.ibb.co/cL5CXyj/18.jpg',
  'https://i.ibb.co/yP4g9fK/19.jpg',
  'https://i.ibb.co/Krj6vt4/20.jpg',
  'https://i.ibb.co/gzHtL6B/21.jpg',
  'https://i.ibb.co/JvnXsxp/22.jpg',
  'https://i.ibb.co/q7r5w5Z/23.jpg',
  'https://i.ibb.co/P9f50yG/24.jpg',
  'https://i.ibb.co/B6DrxqG/25.jpg',
  'https://i.ibb.co/hCjHB9b/26.jpg',
  'https://i.ibb.co/M897kZx/27.jpg',
  'https://i.ibb.co/8YRKBLS/29.jpg',
  'https://i.ibb.co/KmbZhpx/28.jpg',
  'https://i.ibb.co/V3Gchw2/30.jpg',
  'https://i.ibb.co/cCMC8gW/31.jpg',
  'https://i.ibb.co/VMXzs9S/32.jpg',
];

const atlantaThumbnails = [
  'https://i.ibb.co/dPzrk9t/1.jpg',
  'https://i.ibb.co/vqfZvCR/2.jpg',
  'https://i.ibb.co/VBjnKDw/4.jpg',
  'https://i.ibb.co/qgb3SzF/3.jpg',
  'https://i.ibb.co/4Phpp8s/5.jpg',
  'https://i.ibb.co/c6y0s6W/6.jpg',
  'https://i.ibb.co/FXbTn0X/7.jpg',
  'https://i.ibb.co/RjCLyZy/8.jpg',
  'https://i.ibb.co/ZGLFkYX/9.jpg',
  'https://i.ibb.co/gMjx7wL/10.jpg',
  'https://i.ibb.co/qDW4WFJ/11.jpg',
  'https://i.ibb.co/PQYYPx7/12.jpg',
  'https://i.ibb.co/zX2CRDk/13.jpg',
  'https://i.ibb.co/BZvwzfc/14.jpg',
  'https://i.ibb.co/2qBm7nv/15.jpg',
  'https://i.ibb.co/mGR1pcR/16.jpg',
  'https://i.ibb.co/qdP5TM4/17.jpg',
  'https://i.ibb.co/GTwt3dq/18.jpg',
  'https://i.ibb.co/QQDYgbh/19.jpg',
  'https://i.ibb.co/0Gjqb04/20.jpg',
  'https://i.ibb.co/ZWtcpNk/21.jpg',
  'https://i.ibb.co/hBspmXd/22.jpg',
  'https://i.ibb.co/Gn5TjTh/23.jpg',
  'https://i.ibb.co/QC16tVv/24.jpg',
  'https://i.ibb.co/hd5ZjDc/25.jpg',
  'https://i.ibb.co/mSwt9Tk/26.jpg',
  'https://i.ibb.co/0BQrymR/27.jpg',
  'https://i.ibb.co/J3bBdwM/29.jpg',
  'https://i.ibb.co/YbP9QFB/28.jpg',
  'https://i.ibb.co/PtyvbDw/30.jpg',
  'https://i.ibb.co/QCzCHbW/31.jpg',
  'https://i.ibb.co/8PFfHs5/32.jpg',
];

const graduationPhotos = [
  'https://i.ibb.co/V01yt6P/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/yhQhRXX/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/QrwGYCH/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/M9mQ67Y/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/tBqTPzD/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/rFptm9M/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/dmYVmNR/8.jpg',
  'https://i.ibb.co/J2TyCJF/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/n3bqdfM/10.jpg',
  'https://i.ibb.co/YZH1bTQ/11.jpg',
  'https://i.ibb.co/HCsFcVt/12.jpg',
  'https://i.ibb.co/K2kDrg9/13.jpg',
  'https://i.ibb.co/D7n1thR/14.jpg',
  'https://i.ibb.co/Yt0dmhg/15.jpg',
];

const graduationThumbnails = [
  'https://i.ibb.co/gb213YB/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/gvRv733/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/PwH819W/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/DLZTpQx/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/Mncx5f7/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/YPdW3g8/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/VDGQ34p/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/phFMhHS/8.jpg',
  'https://i.ibb.co/N1sgDp2/10.jpg',
  'https://i.ibb.co/X8cKVkL/11.jpg',
  'https://i.ibb.co/9hL8xNb/12.jpg',
  'https://i.ibb.co/hBjcmNK/13.jpg',
  'https://i.ibb.co/nDSwb9L/14.jpg',
  'https://i.ibb.co/9qZ46WP/15.jpg',
];

const europePhotos = [
  'https://i.ibb.co/Srx0DPR/2.jpg',
  'https://i.ibb.co/XSv0WyS/3.jpg',
  'https://i.ibb.co/XLYNHdx/4.jpg',
  'https://i.ibb.co/tC29sND/5.jpg',
  'https://i.ibb.co/fFcfYs4/6.jpg',
  'https://i.ibb.co/sywqMFj/7.jpg',
  'https://i.ibb.co/PwXx5DQ/8.jpg',
  'https://i.ibb.co/9rPn08y/9.jpg',
  'https://i.ibb.co/3N0XQ3V/10.jpg',
  'https://i.ibb.co/7jtqPWH/11.jpg',
  'https://i.ibb.co/BcLcK2n/12.jpg',
  'https://i.ibb.co/hXqJn26/13.jpg',
  'https://i.ibb.co/dtxbStV/14.jpg',
  'https://i.ibb.co/5v0zMWJ/15.jpg',
  'https://i.ibb.co/4Fw3dvX/16.jpg',
  'https://i.ibb.co/mNXCk1s/17.jpg',
  'https://i.ibb.co/WF7H191/18.jpg',
  'https://i.ibb.co/YkNXrhC/19.jpg',
  'https://i.ibb.co/FnvFYkZ/20.jpg',
  'https://i.ibb.co/GdBXQ5D/21.jpg',
  'https://i.ibb.co/prfkhbs/22.jpg',
  'https://i.ibb.co/sH4g3Jr/23.jpg',
  'https://i.ibb.co/0CznfY0/24.jpg',
  'https://i.ibb.co/MMZQ3c2/25.jpg',
  'https://i.ibb.co/N37kXdn/26.jpg',
  'https://i.ibb.co/crt7Kwk/27.jpg',
  'https://i.ibb.co/LCrDvNF/28.jpg',
  'https://i.ibb.co/PGx5CVc/29.jpg',
  'https://i.ibb.co/sCxmL6z/30.jpg',
  'https://i.ibb.co/ZRsZVf8/31.jpg',
  'https://i.ibb.co/VM2ZV2L/32.jpg',
  'https://i.ibb.co/8gcqHnk/33.jpg',
  'https://i.ibb.co/vmXt1gy/34.jpg',
  'https://i.ibb.co/T2WC2Bf/35.jpg',
  'https://i.ibb.co/09y7cph/36.jpg',
];

const europeThumbnails = [
  'https://i.ibb.co/8cB2n06/2.jpg',
  'https://i.ibb.co/t8WjHZ8/3.jpg',
  'https://i.ibb.co/nmPZpVM/4.jpg',
  'https://i.ibb.co/pXKSzsd/5.jpg',
  'https://i.ibb.co/sRBnvZy/6.jpg',
  'https://i.ibb.co/ZW8xkhd/7.jpg',
  'https://i.ibb.co/LnjSCN5/8.jpg',
  'https://i.ibb.co/4VkWLm4/9.jpg',
  'https://i.ibb.co/wWKHjk8/10.jpg',
  'https://i.ibb.co/0myxTMk/11.jpg',
  'https://i.ibb.co/fqMqGdD/12.jpg',
  'https://i.ibb.co/0BwRWY7/13.jpg',
  'https://i.ibb.co/YXwD6X1/14.jpg',
  'https://i.ibb.co/y59Mfnx/15.jpg',
  'https://i.ibb.co/RgGdyrs/16.jpg',
  'https://i.ibb.co/qJWMKXG/17.jpg',
  'https://i.ibb.co/hHrgv5v/18.jpg',
  'https://i.ibb.co/Lt0rG1F/19.jpg',
  'https://i.ibb.co/9GX74mK/20.jpg',
  'https://i.ibb.co/grHGZR8/21.jpg',
  'https://i.ibb.co/vv3MXVr/22.jpg',
  'https://i.ibb.co/jRY84ZF/23.jpg',
  'https://i.ibb.co/v3McJLN/24.jpg',
  'https://i.ibb.co/cDkV76g/25.jpg',
  'https://i.ibb.co/4fJDcng/26.jpg',
  'https://i.ibb.co/DRkSPYz/27.jpg',
  'https://i.ibb.co/648cNXM/28.jpg',
  'https://i.ibb.co/hFsK8TB/29.jpg',
  'https://i.ibb.co/RNrvLBd/30.jpg',
  'https://i.ibb.co/Y49r3Nk/31.jpg',
  'https://i.ibb.co/ssyGjyg/32.jpg',
  'https://i.ibb.co/b5JTkjt/33.jpg',
  'https://i.ibb.co/MpBXnWw/34.jpg',
  'https://i.ibb.co/sRWBRvf/35.jpg',
  'https://i.ibb.co/X5xQbfS/36.jpg',
];

const malabarPhotos = [
  'https://i.ibb.co/G2rNN0M/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/yBLtHhk/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/LkHbZG5/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/SNtjFkS/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/yhgXm7d/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/3ygNFNj/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/dWVWGS1/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/6FCy4jH/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/wwn0p4b/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/yqYzk2Z/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/yX7NDK6/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/yFLY9zr/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/926HXmz/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/D1zhM7J/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/YNFksk4/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/0sQCWtF/Processed-with-VSCO-with-hb2-preset.jpg',
];

const malabarThumbnails = [
  'https://i.ibb.co/bBGDDPv/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/bRpTcHv/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/r7NTwWs/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/Nx9vc0R/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/6vDmcLr/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/fYjpNpy/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/SsMsR2W/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/XtRWzc7/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/D8BVwQ6/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/TtbQ0y7/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/DtT1Nm9/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/0yPF70x/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/jGmkK9B/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/cyk08NB/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/vc6PnPC/Processed-with-VSCO-with-hb2-preset.jpg',
  'https://i.ibb.co/Rj73MyB/Processed-with-VSCO-with-hb2-preset.jpg',
];

const katoombaPhotos = [
  'https://i.ibb.co/4pXhsTF/2.jpg',
  'https://i.ibb.co/S6qYpnD/3.jpg',
  'https://i.ibb.co/nswWgk8/4.jpg',
  'https://i.ibb.co/wwD5MbJ/5.jpg',
  'https://i.ibb.co/dQWTmkd/6.jpg',
  'https://i.ibb.co/HzSRqGt/7.jpg',
  'https://i.ibb.co/PgBpQyN/8.jpg',
  'https://i.ibb.co/K21c11W/9.jpg',
  'https://i.ibb.co/gdKcyKj/10.jpg',
  'https://i.ibb.co/L9W41fL/11.jpg',
  'https://i.ibb.co/3MpZJPy/12.jpg',
  'https://i.ibb.co/DfhQFqG/13.jpg',
  'https://i.ibb.co/0y6S8dH/14.jpg',
  'https://i.ibb.co/vQYwqd4/15.jpg',
  'https://i.ibb.co/8rWYhTd/16.jpg',
  'https://i.ibb.co/DG5swTH/17.jpg',
  'https://i.ibb.co/NpBtTyM/18.jpg',
  'https://i.ibb.co/xjj79vC/19.jpg',
  'https://i.ibb.co/8DGc4c9/20.jpg',
  'https://i.ibb.co/Ldm3pNm/21.jpg',
  'https://i.ibb.co/1bt0QbW/22.jpg',
  'https://i.ibb.co/bLGFfH8/23.jpg',
  'https://i.ibb.co/sKs1n6L/24.jpg',
  'https://i.ibb.co/j438qkt/25.jpg',
  'https://i.ibb.co/gmLB4rH/26.jpg',
  'https://i.ibb.co/Y74d9Zb/27.jpg',
  'https://i.ibb.co/N31wj0t/28.jpg',
  'https://i.ibb.co/PMK1Ng8/29.jpg',
  'https://i.ibb.co/HNsfWft/30.jpg',
  'https://i.ibb.co/c8SH3FH/31.jpg',
  'https://i.ibb.co/DG9WVLx/32.jpg',
  'https://i.ibb.co/smBTD1v/33.jpg',
  'https://i.ibb.co/dpTnVh4/34.jpg',
];

const katoombaThumbnails = [
  'https://i.ibb.co/vkT0sPc/2.jpg',
  'https://i.ibb.co/VLXn8S0/3.jpg',
  'https://i.ibb.co/VWLKjBp/4.jpg',
  'https://i.ibb.co/pRCYJsW/5.jpg',
  'https://i.ibb.co/YtRC0fz/6.jpg',
  'https://i.ibb.co/2t9Rdcv/7.jpg',
  'https://i.ibb.co/Yhv9ZS3/8.jpg',
  'https://i.ibb.co/x14K446/9.jpg',
  'https://i.ibb.co/r3rXkr7/10.jpg',
  'https://i.ibb.co/NWPwKz8/11.jpg',
  'https://i.ibb.co/qWNQZ2M/12.jpg',
  'https://i.ibb.co/tpgD6dZ/13.jpg',
  'https://i.ibb.co/b5CZq8c/14.jpg',
  'https://i.ibb.co/KLw0hsx/15.jpg',
  'https://i.ibb.co/4861Q9Y/16.jpg',
  'https://i.ibb.co/yhWGg7c/17.jpg',
  'https://i.ibb.co/K7vbKrJ/18.jpg',
  'https://i.ibb.co/R00Drnv/19.jpg',
  'https://i.ibb.co/MDK6567/20.jpg',
  'https://i.ibb.co/fdfs0Df/21.jpg',
  'https://i.ibb.co/V3cL934/22.jpg',
  'https://i.ibb.co/XpGDwyv/23.jpg',
  'https://i.ibb.co/VgMCRQX/24.jpg',
  'https://i.ibb.co/ThPwJMy/25.jpg',
  'https://i.ibb.co/ydcb4NG/26.jpg',
  'https://i.ibb.co/mCrys8S/27.jpg',
  'https://i.ibb.co/8BzF5yN/28.jpg',
  'https://i.ibb.co/W0ZB6f1/29.jpg',
  'https://i.ibb.co/N1Pd8d9/30.jpg',
  'https://i.ibb.co/xgt0Lj0/31.jpg',
  'https://i.ibb.co/n37mgRH/32.jpg',
  'https://i.ibb.co/bWf4yvg/33.jpg',
  'https://i.ibb.co/7pf7Z9t/34.jpg',  
];

const laperousePhotos = [
  'https://i.ibb.co/k94qjK6/2.jpg',
  'https://i.ibb.co/J7p5n4C/3.jpg',
  'https://i.ibb.co/HG1L0Fm/4.jpg',
  'https://i.ibb.co/w4wHVMs/5.jpg',
  'https://i.ibb.co/RgN21bT/6.jpg',
  'https://i.ibb.co/28c2C5n/7.jpg',
  'https://i.ibb.co/rHfrzby/8.jpg',
  'https://i.ibb.co/D7H8cFK/9.jpg',
  'https://i.ibb.co/mqf5BM7/10.jpg',
];

const laperouseThumbnails = [
  'https://i.ibb.co/wwSpVyr/2.jpg',
  'https://i.ibb.co/C79hBZW/3.jpg',
  'https://i.ibb.co/VMr0djX/4.jpg',
  'https://i.ibb.co/njn29zC/5.jpg',
  'https://i.ibb.co/4F2WctR/6.jpg',
  'https://i.ibb.co/0BYN6QD/7.jpg',
  'https://i.ibb.co/XtkRKyJ/8.jpg',
  'https://i.ibb.co/mHVXZKz/9.jpg',
  'https://i.ibb.co/jMnk51F/10.jpg',  
];

const sunrisesPhotos = [
  'https://i.ibb.co/ryYQVnD/2.jpg',
  'https://i.ibb.co/FxrBk7R/3.jpg',
  'https://i.ibb.co/bWwytz3/4.jpg',
  'https://i.ibb.co/HXD7F7P/5.jpg',
  'https://i.ibb.co/sjP3wH5/6.jpg',
  'https://i.ibb.co/BcnYjbd/7.jpg',
  'https://i.ibb.co/KhZX4wv/8.jpg',
  'https://i.ibb.co/X7tW5gn/9.jpg',
  'https://i.ibb.co/X37tQkf/10.jpg',
  'https://i.ibb.co/w4p8YPW/11.jpg',
  'https://i.ibb.co/YBpmnZg/12.jpg',
  'https://i.ibb.co/CzwqHmZ/13.jpg',
];

const sunrisesThumbnails = [
  'https://i.ibb.co/zfpmzKq/2.jpg',
  'https://i.ibb.co/h76Kx83/3.jpg',
  'https://i.ibb.co/HFwMZn2/4.jpg',
  'https://i.ibb.co/pnv202K/5.jpg',
  'https://i.ibb.co/b13dBJH/6.jpg',
  'https://i.ibb.co/QpDxnLz/7.jpg',
  'https://i.ibb.co/4ty7vdr/8.jpg',
  'https://i.ibb.co/ZBxfgPQ/9.jpg',
  'https://i.ibb.co/b2sX0Rj/10.jpg',
  'https://i.ibb.co/KWVBmfq/11.jpg',
  'https://i.ibb.co/hFDbQf0/12.jpg',
  'https://i.ibb.co/c1yPrkH/13.jpg',  
];

const images = new Map<string, ImageCollection>();

const collections = [
  {
    folderName: 'sunrises',
    displayName: 'Brighton Le-Sands Sunrises',
    photos: sunrisesPhotos,
    thumbnails: sunrisesThumbnails,
    date: 'July, 2021',
  },
  {
    folderName: 'katoomba',
    displayName: 'Katoomba',
    photos: katoombaPhotos,
    thumbnails: katoombaThumbnails,
    date: 'March, 2021',
  },
  {
    folderName: 'laperouse',
    displayName: 'La Perouse Beach',
    photos: laperousePhotos,
    thumbnails: laperouseThumbnails,
    date: 'January, 2021',
  },
  {
    folderName: 'malabar',
    displayName: 'Malabar Headland National Park',
    photos: malabarPhotos,
    thumbnails: malabarThumbnails,
    date: 'July, 2020',
  },
  {
    folderName: 'graduation',
    displayName: 'My Graduation',
    photos: graduationPhotos,
    thumbnails: graduationThumbnails,
    date: 'March, 2019'
  },
  {
    folderName: 'weddingparty',
    displayName: "Carol and Nathan's Wedding Party",
    photos: weddingPartyImages,
    thumbnails: weddingPartyThumbnails,
    date: 'March, 2019'
  },
  {
    folderName: 'europe',
    displayName: 'Paris & Venice',
    photos: europePhotos,
    thumbnails: europeThumbnails,
    date: 'July, 2018'
  },
  {
    folderName: 'seattle',
    displayName: 'Seattle',
    date: 'February, 2018',
    photos: seattlePhotos,
    thumbnails: seattleThumbnails,
  },
  {
    folderName: 'atlantachristmas',
    displayName: 'Christmas in Atlanta',
    photos: atlantaPhotos,
    thumbnails: atlantaThumbnails,
    date: 'December, 2017'
  },
];

collections.forEach(({
  folderName,
  displayName,
  thumbnails,
  date,
  photos,
}) => {
  images.set(folderName, {
    photos,
    thumbnails,
    displayName,
    folderName,
    date,
  });
});

export default images;
